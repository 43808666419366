<template>
  <div class="caseRevision h-100">
    <div class="container h-100 py-5">
      <h3 class="text-capitalize pt-2 pt-md-5"
          data-aos="fade-down-right"
          data-aos-easing="ease-in-out"
          data-aos-duration="400"
      >
        {{ this.$store.getters["patientHistory"].Label }}
      </h3>
      <div
        class="row row-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="row py-5">
          <div class="col-md-6 pb-2 pb-md-5"
               data-aos="fade-up-right"
               data-aos-easing="ease-in-out"
               data-aos-duration="600"
          >
            <table class="table table-responsive table-bordered">
              <tbody>
                <tr>
                  <th scope="row">Name</th>
                  <td>
                    {{ this.$store.getters["patientHistory"].patient_name }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Age</th>
                  <td>
                    {{ this.$store.getters["patientHistory"].patient_age }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Incident Description</th>
                  <td>
                    <span
                      v-html="
                        this.$store.getters['patientHistory']
                          .incident_description
                      "
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6 pb-5 pb-md-5"
               data-aos="fade-left"
               data-aos-easing="ease-in-out"
               data-aos-duration="600"
          >
            <img src="@/assets/images/case_revision.jpg" alt="" class="w-100 rounded" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Case Revision",
  data() {
    return {};
  },
  mounted(){
    this.$store.dispatch('prevNavigationStatus', true)
    this.$store.dispatch( 'setInstructions', '' )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
</style>